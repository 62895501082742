import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../../../assets/image/logo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoginRedirection from "../../Service/LoginRedirection";
import TokenUndefined from "../../Service/TokenUndefined";
import { message } from "antd";
function ExhibitorDashboardNavbar() {
  const [showOwner, setShowOwner] = useState(false);
  const [showStaff, setShowStaff] = useState(false);
  const [showVIPGuest, setshowVIPGuest] = useState(false);
  const [showSetting, setshowSetting] = useState(false);
  const [exhibitorName, setExhibitorName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showFamily, setShowFamily] = useState(false);

  const navigate = useNavigate();

  const showOwnerDropdown = (e) => {
    setShowOwner(!showOwner);
  };
  const hideOwnerDropdown = (e) => {
    setShowOwner(false);
  };

  const showStaffDropdown = (e) => {
    setShowStaff(!showStaff);
  };
  const hideStaffDropdown = (e) => {
    setShowStaff(false);
  };

  const showModalDropdown = (e) => {
    setShowModal(!showModal);
  };
  const hideModalDropdown = (e) => {
    setShowModal(false);
  };

  const showFamilyDropdown = (e) => {
    setShowFamily(!showModal);
  };
  const hideFamilyDropdown = (e) => {
    setShowFamily(false);
  };

  const showVIPGuestDropdown = (e) => {
    setshowVIPGuest(!showVIPGuest);
  };
  const hideVIPGuestDropdown = (e) => {
    setshowVIPGuest(false);
  };
  const showSettingDropdown = (e) => {
    setshowSetting(!showSetting);
  };
  const hideSettingDropdown = (e) => {
    setshowSetting(false);
  };

  const handleLogout = (e) => {
    localStorage.removeItem("jff_token");
    localStorage.removeItem("category");
    localStorage.removeItem("permission");
    navigate("/exhibitor/login");
  };

  useEffect(() => {
    TokenUndefined("exhibitor/login");
    axios
      .post(`${process.env.REACT_APP_API_END_POINT}/get-exhibitor-name`, "", {
        headers: {
          token: localStorage.getItem("jff_token"),
        },
      })
      .then((res) => {
        // LoginRedirection(res, "exhibitor/login");
        setExhibitorName(res.data.data);
      })
      .catch((err) => {
        // LoginRedirection("/superadmin/login");
        if (err.response.status === 401) {
          navigate("/exhibitor/login");
          localStorage.removeItem("jff_token");
          localStorage.removeItem("category");
          localStorage.removeItem("permission");
          localStorage.removeItem("user_id");
          localStorage.removeItem("type_of_user");
        }
      });
  }, []);

  const handleAddOwnerClick = () => {
    axios
      .get(`${process.env.REACT_APP_API_END_POINT}/form-status-exhibitor`, {
        headers: {
          token: localStorage.getItem("jff_token"),
        },
      })
      .then((res) => {
        console.log(res.data);

        if (res.data.ownerstatus) {
          navigate("/exhibitor/exhibitor-owner-registration");
        } else {
          message.error("Limit Exhaust");
        }
      });
  };
  const handleAddStaffClick = () => {
    axios
      .get(`${process.env.REACT_APP_API_END_POINT}/form-status-exhibitor`, {
        headers: {
          token: localStorage.getItem("jff_token"),
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.staffstatus) {
          navigate("/exhibitor/exhibitor-staff-registration");
        } else {
          message.error("Limit Exhaust");
        }
      });
  };
  const handleAddModalClick = () => {
    navigate("/exhibitor/exhibitor-modal-registration");
  };

  const handleAddFamilyClick = () => {
    axios
      .get(`${process.env.REACT_APP_API_END_POINT}/form-status-exhibitor`, {
        headers: {
          token: localStorage.getItem("jff_token"),
        },
      })
      .then((res) => {
        console.log(res.data);

        if (res.data.familyStatus) {
          navigate("/exhibitor/exhibitor-family-registration");
        } else {
          message.error("Limit Exhaust");
        }
      });
  };

  const handleAddViprClick = () => {
    // axios
    //   .get(`${process.env.REACT_APP_API_END_POINT}/form-status-exhibitor`, {
    //     headers: {
    //       token: localStorage.getItem("jff_token"),
    //     },
    //   })
    //   .then((res) => {
    //     console.log(res.data)
    //     if (res.data.vipstatus) {
    navigate("/exhibitor/exhibitor-vip-registration");
    //   } else {
    //     message.error("Limit Exhaust");
    //   }
    // });
  };

  return (
    <>
      <div className="d-flex">
        {" "}
        <img
          src={Logo}
          alt="logo-img"
          className="my-1 mx-5"
          style={{ height: "100px", width: "100px" }}
          onClick={() => navigate("/exhibitor")}
        />
        <div className="d-flex mx-auto align-items-center">
          <span className="fw-bold fs-1">{exhibitorName}</span>
        </div>
        {/* <div className="align-items-center d-flex">
          <h5 className="text-danger">
            Facing Problem Please Call:-{" "}
            <a
              className="text-danger text-decoration-none"
              href="tel: +91-7737450029"
            >
              +91-7737450029
            </a>{" "}
          </h5>
        </div> */}
      </div>
      <Navbar
        bg="dark"
        expand="lg"
        data-bs-theme="dark"
        className="bg-body-tertiary"
      >
        <Container>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
              {" "}
              <Nav.Link onClick={() => navigate("/exhibitor/dashboard")}>
                Dashboard
              </Nav.Link>
              <NavDropdown
                title="Owner"
                id="collasible-nav-dropdown"
                show={showOwner}
                onMouseEnter={showOwnerDropdown}
                onMouseLeave={hideOwnerDropdown}
              >
                {" "}
                {/* <NavDropdown.Item onClick={() => handleAddOwnerClick()}>
                  Add Owner
                </NavDropdown.Item>
                <NavDropdown.Divider /> */}
                <NavDropdown.Item
                  onClick={() => navigate("/exhibitor/owner-list")}
                >
                  Owner List
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Staff"
                id="collasible-nav-dropdown"
                show={showStaff}
                onMouseEnter={showStaffDropdown}
                onMouseLeave={hideStaffDropdown}
              >
                {" "}
                {/* <NavDropdown.Item onClick={() => handleAddStaffClick()}>
                  Add Staff
                </NavDropdown.Item>
                <NavDropdown.Divider /> */}
                <NavDropdown.Item
                  onClick={() => navigate("/exhibitor/staff-list")}
                >
                  Staff List
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Model"
                id="collasible-nav-dropdown"
                show={showModal}
                onMouseEnter={showModalDropdown}
                onMouseLeave={hideModalDropdown}
              >
                {" "}
                {/* <NavDropdown.Item onClick={() => handleAddModalClick()}>
                  Add Model
                </NavDropdown.Item>
                <NavDropdown.Divider /> */}
                <NavDropdown.Item
                  onClick={() => navigate("/exhibitor/modal-list")}
                >
                  Model List
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Family"
                id="collasible-nav-dropdown"
                show={showFamily}
                onMouseEnter={showFamilyDropdown}
                onMouseLeave={hideFamilyDropdown}
              >
                {" "}
                {/* <NavDropdown.Item onClick={() => handleAddFamilyClick()}>
                  Add Family
                </NavDropdown.Item>
                <NavDropdown.Divider /> */}
                <NavDropdown.Item
                  onClick={() => navigate("/exhibitor/family-list")}
                >
                  Family List
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="VIP Guest"
                id="collasible-nav-dropdown"
                show={showVIPGuest}
                onMouseEnter={showVIPGuestDropdown}
                onMouseLeave={hideVIPGuestDropdown}
              >
                {/* <NavDropdown.Item onClick={() => handleAddViprClick()}>
                  Add VIP Guest
                </NavDropdown.Item>
                <NavDropdown.Divider /> */}
                <NavDropdown.Item
                  onClick={() => navigate("/exhibitor/vip-guest-requrest-list")}
                >
                  VIP Guest Request List
                </NavDropdown.Item>

                <NavDropdown.Divider />
                <NavDropdown.Item
                  onClick={() => navigate("/exhibitor/vip-guest-list")}
                >
                  VIP Guest Approved List
                </NavDropdown.Item>
                {/* <NavDropdown.Divider />{" "}
                <NavDropdown.Item
                  onClick={() => navigate("/exhibitor/recycle-vip-guest-list")}
                >
                  Recycled VIP Guest List
                </NavDropdown.Item> */}
              </NavDropdown>
              <NavDropdown
                title="Setting"
                id="collasible-nav-dropdown"
                show={showSetting}
                onMouseEnter={showSettingDropdown}
                onMouseLeave={hideSettingDropdown}
              >
                <NavDropdown.Item
                  onClick={() => navigate("/exhibitor/change-password")}
                >
                  Change Password
                </NavDropdown.Item>

                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => handleLogout()}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ExhibitorDashboardNavbar;
