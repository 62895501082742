import React, { useEffect, useState } from "react";
import VisitorForm from "./VisitorForm";
import axios from "axios";
import ExhibitorDashboardNavbar from "../exhibitor/exhibitor_dashboard/ExhibitorDashboardNavbar";

const ExhibitorOwner = () => {
  const [show,setShow]=useState(true)
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_END_POINT}/get-registration-visibility`,      
      )
      .then((res) => {
      
        // setExhibitorStaff(res.data.exhibitor_staff);
        if (res.data.data.exhibitor_owner==="active"){

          setShow(true);
        } else{
          setShow(false);

        }
        // setExhibitorVip(res.data.exhibitor_vip);
        // setVisitor(res.data.visitor);
        // setVendorStaff(res.data.vendor_staff);
        // setVendorOwner(res.data.vendor_owner);
        // localStorage.setItem("exhibitorStaff", res.data.data.exhibitor_staff);
        // localStorage.setItem("exhibitorOwner", res.data.data.exhibitor_owner);
        // localStorage.setItem("exhibitorVip", res.data.data.exhibitor_vip);
        // localStorage.setItem("visitor", res.data.data.visitor);
        // localStorage.setItem("vendorStaff", res.data.data.vendor_staff);
        // localStorage.setItem("vendorOwner", res.data.data.vendor_owner);
      });
  }, []);
  console.log(
    "asassadsdsaasdadssadssdad",
    localStorage.getItem("exhibitorOwner")
  );
  return (
    <>
      <ExhibitorDashboardNavbar />
      {show ? (
        <VisitorForm
          formHeader={"Exhibitor Owner Registration"}
          formName={"exhibitor_owner"}
          barcodeId={""}
        />
      ) : (
        "Form is currently suspended"
      )}
    </>
  );
};

export default ExhibitorOwner;
