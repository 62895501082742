import React from 'react'

const Footer = () => {
    return (
      <div className="footer">
        Copyright © Jaipur Garment Club (JGC). All Rights Reserved.
      </div>
    );
}

export default Footer