import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Wrong404 from "../404/Wrong404";
import routes from "./Route";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AppContent = () => {
  const [data, setData] = useState(false);
  const [wrong, setWrong] = useState(false);
  const [category, setCategory] = useState([]);
  const navigate = useNavigate();

  const handleRoute = async () => {
    let storedCategory = [];
    storedCategory = localStorage.getItem("permission");


    // if (!token) {
    //   window.location.href = `${process.env.REACT_APP_LOGIN_REDIRECTION_URL}/${routevar}`;
    // }
    
    if (storedCategory) {
      const categoryArray = JSON.parse(storedCategory);
      axios
        .post(`${process.env.REACT_APP_API_END_POINT}/get-permission-name`, {
          permission: categoryArray,
        })
        .then((res) => {
          console.log(".data.data", res.data.data);

          setCategory(res.data.data);
        })

      setData(true);
    }
  };

  useEffect(() => {
    handleRoute();
    setWrong(true);
  }, []);
  console.log("category", category);

  const matchingObjects = routes.filter((obj) =>
    category.includes(obj.booleanValue)
  );

  return (
    <Routes>
      {data &&
        matchingObjects?.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            exact={route.exact}
            name={route.name}
            element={<route.element />}
          />
        ))}
      {!data && wrong && <Route path="*" element={<Wrong404 />} />}
    </Routes>
  );
};
export default React.memo(AppContent);
