import React, { useEffect, useState } from "react";
import VisitorForm from "./VisitorForm";
import { useParams } from "react-router-dom";

import axios from "axios";

const VendorStaff = () => {
  const [show, setShow] = useState(true);
    const { barcode } = useParams();
  useEffect(() => {
      axios
        .get(
          `${process.env.REACT_APP_API_END_POINT}/get-registration-visibility`
        )
        .then((res) => {
          console.log(
            "here is data come from 32 line of the  registration setting ",
            res.data
          );
          // setExhibitorStaff(res.data.exhibitor_staff);
          if (res.data.data.vendor_staff === "active") {
            setShow(true);
          } else {
            setShow(false);
          }
          // setExhibitorVip(res.data.exhibitor_vip);
          // setVisitor(res.data.visitor);
          // setVendorStaff(res.data.vendor_staff);
          // setVendorOwner(res.data.vendor_owner);
          // localStorage.setItem("exhibitorStaff", res.data.data.exhibitor_staff);
          // localStorage.setItem("exhibitorOwner", res.data.data.exhibitor_owner);
          // localStorage.setItem("exhibitorVip", res.data.data.exhibitor_vip);
          // localStorage.setItem("visitor", res.data.data.visitor);
          // localStorage.setItem("vendorStaff", res.data.data.vendor_staff);
          // localStorage.setItem("vendorOwner", res.data.data.vendor_owner);
        });
  }, []);
  return (
    <>
      {show ? (
        <VisitorForm
          formHeader={"Vendor staff registration"}
          formName={"vendor_staff"}
          barcodeId={barcode}
        />
      ) : (
        "Form is currently suspended"
      )}
    </>
  );
};

export default VendorStaff;
